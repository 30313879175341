<template>
<div v-if="downloadShow" class="downLoad_float">
    <div class="downLoad_con">
        <i @click="downloadClose()"></i>
        <img class="downLoad_logo" src="@/assets/images/officialWebsite/scanCode_logo.png" alt="传奇云游戏">
        <div class="downLoad_scan" v-html="resource.resource">
            {{resource.resource}}
        </div>
        <div class="downLoad_word">
            <p>扫描二维码下载 或 <a @click="directDown(resource.loadHref)">直接下载</a> </p>
            <p>传奇云游戏安卓版</p>
        </div>
    </div>
</div>
</template>

<script>
//消息弹框
export default {
    name: 'DownLoadModal',
    data() {
        return {}
    },

    props: {
        downloadShow: {
            type: Boolean,
            default: false,
        },
        resource: {
            type: Object,
            default: {},
        },

    },
    methods: {
        downloadClose() {
            this.$emit('downloadStop', false)
        },
        directDown(url) {
            this.downloadClose()
            window.location.href = url
        }
    }
}
</script>

<style lang="scss" scoped>
.downLoad_float {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
    position: fixed;
    top: 0;
    z-index: 101;
    display: flex;
    justify-content: center;
    align-items: center;

    .downLoad_con {
        width: 550px;
        height: 530px;
        background: #FFFFFF;
        position: relative;

        i {
            width: 56px;
            height: 56px;
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            background: url("../assets/images/officialWebsite/icon-close.png");
            background-size: cover;
        }

        .downLoad_logo {
            width: 370px;
            height: 74px;
            position: absolute;
            top: 40px;
            left: 50%;
            transform: translateX(-50%);
        }

        .downLoad_scan {
            width: 300px;
            height: 308px;
            position: absolute;
            top: 132px;
            left: 50%;
            transform: translateX(-50%);
            background: url("../assets/images/officialWebsite/scanCode_bg.png");
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 2px;

            .downLoad_code {
                width: 255px;
                height: 255px;
            }
        }

        .downLoad_word {
            height: 50px;
            font-size: 16px;
            font-weight: 400;
            color: #2F333D;
            position: absolute;
            top: 448px;
            left: 50%;
            transform: translateX(-50%);

            p {
                padding: 0;
                margin: 0;
                text-align: center;
                a {
                    color: #D69D4D;
                    font-weight: bolder;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
