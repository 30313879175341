<template lang="html">
<div>
    <!-- 导航 -->
    <Header :activeIndex="0"></Header>
    <!-- 背景 -->
    <div class="top-bd">
        <b-container>
            <b-row>
                <b-col lg="6" md="6" cols="12" class="lg-p0">
                    <p class="lead">一站式云游戏运营平台</p>
                    <p class="w87">让玩家在手机、平板、PC、智能电视等主流智能设备上跨终端体验3A游戏。在10Mbps网速下，可实现1080P、60帧、延迟小于40毫秒的游戏体验。</p>
                </b-col>
                <b-col lg="6" md="6" cols="12" class="lg-p0 text-right banner-right">
                    <img class="bd-img img-fluid" src="@/assets/images/officialWebsite/flag.png">
                </b-col>
            </b-row>
        </b-container>
    </div>
    <!-- 卡片 -->
    <div class="card-container">
        <b-container>
            <b-row>
                <b-col lg="3" md="6" cols="12" class="mb-4 lg-p0">
                    <b-card class="card_box">
                        <img class="card-image img-fluid" src="@/assets/images/officialWebsite/light.png">
                        <b-card-text class="card-title">
                            超低时延
                        </b-card-text>
                        <b-card-text class="pt-3">
                            端到端时延增加控制在40ms以内
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col lg="3" md="6" cols="12" class="mb-4 lg-p0">
                    <b-card class="card_box">
                        <img class="card-image img-fluid" src="@/assets/images/officialWebsite/delay.png">
                        <b-card-text class="card-title">
                            弱网支持
                        </b-card-text>
                        <b-card-text class="pt-3">
                            复杂网络环境下画面稳定流畅
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col lg="3" md="6" cols="12" class="mb-4 lg-p0">
                    <b-card class="card_box">
                        <img class="card-image img-fluid" src="@/assets/images/officialWebsite/freely.png">
                        <b-card-text class="card-title">
                            秒级启动
                        </b-card-text>
                        <b-card-text class="pt-3">
                            随时随地快速接入游戏
                        </b-card-text>
                    </b-card>
                </b-col>
                <b-col lg="3" md="6" cols="12" class="mb-4 lg-p0">
                    <b-card class="card_box" style="border-right:none">
                        <img class="card-image img-fluid" src="@/assets/images/officialWebsite/gameGuard.png">
                        <b-card-text class="card-title">
                            超清画质
                        </b-card-text>
                        <b-card-text class="pt-3">
                            稳定支持4K/60FPS渲染与推流
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>

    <!-- 秒开背景 -->
    <div class="fast-bg">
        <div class="max-img">
            <el-carousel trigger="click" :height="imgHeight">
                <el-carousel-item v-for="(item,index) in imgGroup" :key="index">
                    <a :href="item.href">
                        <img id="imgBig" class="fast-bgImg" :src="item.src" alt="传奇云游戏-游戏推广">
                    </a>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="min-img">
            <el-carousel trigger="click" :height="imgMinHeight">
                <el-carousel-item v-for="(item,index) in imgGroup" :key="index">
                    <a :href="item.href">
                        <img id="imgMin" class="fast-bgImg" :src="item.minSrc" alt="传奇云游戏-游戏推广">
                    </a>
                </el-carousel-item>
            </el-carousel>
        </div>
    </div>

    <!-- 游戏案例 -->
    <p class="case-title">云游戏案例
        <span id="backtopLink"></span>
    </p>
    <div class="game-case">
        <b-container>
            <b-row align-v="center" class="game-item-mid game-item">
                <b-col lg="6" md="12" cols="12" class="left mb-4">
                    <p class="p-case-header">
                        <img class="img-fluid img-case" :src="$store.state.baseUrl + '/images/game/icon/icon-909.png'">
                        <span class="name-box">
                            <a target="_blank" href="https://imir.web.sdo.com/" class="name-title"><span class="ml-3">热血传奇</span></a>
                            <span class="name-toast ml-3"><span>秒玩</span><span>云存档</span></span>
                        </span>
                    </p>
                    <p class="p-case-content">
                        21周年庆特别版本“热血传奇加强版”，开启玛法大陆的群雄时代！
                        三端互通，单职业打金，全爆模式，点燃你的激情！
                    </p>
                    <p class="p-case-btn">
                        <b-button @click="startGame(909)" variant="outline-secondary"><img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/pc.png">网页版</b-button>
                        <b-button v-if="!isSdoAccount" class="special-btn" @click="startGame(909, true)" variant="outline-secondary"><img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/pc.png">网页版(盛趣账号)</b-button>
<!--                        <b-button :class="isSdoAccount ? 'ml-4' : ''" variant="outline-secondary" @click="downloadAndroidClient('cn.dygame.android.sdo.rexue')"> <img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/phone.png">安卓版</b-button>-->
                    </p>
                </b-col>
                <b-col lg="6" md="12" cols="12" class="left mb-4">
                    <a class="game-item-img" target="_blank" @click="gameDetail(909)"><img class="img-fluid" src="@/assets/images/officialWebsite/legendgl-bg.png" title="点击可了解游戏详情"></a>
                </b-col>
            </b-row>
        </b-container>
    </div>
    <div class="game-case select-bd">
        <b-container>
            <b-row align-v="center" class="game-item">
                <b-col lg="6" md="12" cols="12" class="left mb-4">
                    <a class="game-item-img" target="_blank" @click="gameDetail(903)"><img class="img-fluid" src="@/assets/images/officialWebsite/blood.png" title="点击可了解游戏详情"></a>
                </b-col>
                <b-col lg="6" md="12" cols="12" class="left mb-4">
                    <p class="p-case-header">
                        <img class="img-fluid img-case" :src="$store.state.baseUrl + '/images/game/icon/nostalgic_edition.png'">
                        <span class="name-box">
                            <a target="_blank" href="https://imir.web.sdo.com/" class="name-title"><span class="ml-3">传奇归来</span></a>
                            <span class="name-toast ml-3"><span>秒玩</span><span>云存档</span></span>
                        </span>
                    </p>
                    <p class="p-case-content">
                      “传奇归来-新归来21区”是传奇云游戏辅助端游开区的典型案例，该案例中除最基础的三端互通外，还实现了账号打通、云端存档、云端支付等功能。
                      此外，传奇云游戏独特的“秒开”策略也在本次推广中得到验证，用户仅需数秒（3秒内）便可进入游戏体验。
                    </p>
                    <p class="p-case-btn">
                        <b-button @click="startGame(903)" variant="outline-secondary"><img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/pc.png">网页版</b-button>
                        <b-button v-if="!isSdoAccount" class="special-btn" @click="startGame(903, true)" variant="outline-secondary"><img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/pc.png">网页版(盛趣账号)</b-button>
<!--                        <b-button :class="isSdoAccount ? 'ml-4' : ''" variant="outline-secondary" @click="downloadAndroidClient('cn.dygame.android.sdo.guilai')"> <img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/phone.png">安卓版</b-button>-->
                    </p>
                </b-col>
            </b-row>
        </b-container>
    </div>
<!--    <div class="game-case">-->
<!--        <b-container>-->
<!--            <b-row align-v="center" class="game-item-mid game-item">-->
<!--                <b-col lg="6" md="12" cols="12" class="left mb-4">-->
<!--                    <p class="p-case-header">-->
<!--                        <img class="img-fluid img-case" :src="$store.state.baseUrl + '/images/game/icon/icon-911.png'">-->
<!--                        <span class="name-box">-->
<!--                            <a target="_blank" href="https://mir3.web.sdo.com/web7/index.html"><span class="ml-3">传奇3</span></a>-->
<!--                        </span>-->
<!--                    </p>-->
<!--                    <p class="p-case-content">-->
<!--                        延续《传奇》世界观，融合系列游戏特点的经典之作迎来十周年庆礼！玩家可以从网页以及叨鱼社区直接免客户端登陆游戏，实现多终端互通，畅游玛法大陆。-->
<!--                    </p>-->
<!--                    <p class="p-case-btn">-->
<!--                        <b-button v-if="!isSdoAccount" class="special-btn" @click="startGame(911, true)" variant="outline-secondary"><img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/pc.png">网页版(盛趣账号)</b-button>-->
<!--                        <b-button v-else @click="startGame(911)" variant="outline-secondary"><img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/pc.png">网页版</b-button>-->
<!--                    </p>-->
<!--                </b-col>-->
<!--                <b-col lg="6" md="12" cols="12" class="left mb-4">-->
<!--                    <a target="_blank" class="game-item-img" @click="gameDetail(911)"><img class="img-fluid" src="@/assets/images/officialWebsite/legend_3.png" title="点击可了解游戏详情"></a>-->
<!--                </b-col>-->
<!--            </b-row>-->
<!--        </b-container>-->
<!--    </div>-->
    <div class="game-case select-bd">
        <b-container>
            <b-row align-v="center" class="game-item">
                <b-col lg="6" md="12" cols="12" class="left mb-4">
                    <a class="game-item-img" target="_blank" @click="gameDetail(900)"><img class="img-fluid" src="@/assets/images/officialWebsite/legend.png" title="点击可了解游戏详情"></a>
                </b-col>
                <b-col lg="6" md="12" cols="12" class="left mb-4">
                    <p class="p-case-header">
                        <img class="img-fluid img-case" :src="$store.state.baseUrl + '/images/game/icon/legendary_world.png'">
                        <span class="name-box">
                            <a target="_blank" href="http://woool.sdo.com" class="name-title"><span class="ml-3">传奇世界</span></a>
                        </span>
                    </p>
                    <p class="p-case-content">
                        《传奇世界》时光回归新区现已震撼开启，女神李纯倾情推荐！新区全民绑元返还，最高可领取20w绑元！加入神武军训练计划，有机会获得雷霆怒斩级武器！还有最强者角逐比拼，赢取珍稀豪礼！
                    </p>
                    <p class="p-case-btn">
                        <b-button @click="startGame(900)" variant="outline-secondary"><img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/pc.png">网页版</b-button>
<!--                        <b-button class="ml-4" variant="outline-secondary" @click="downloadAndroidClient('cn.dygame.android.sdo.chuanshi')"> <img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/phone.png">安卓版</b-button>-->
                    </p>
                </b-col>
            </b-row>
        </b-container>
    </div>
    <div class="game-case">
        <b-container>
            <b-row align-v="center" class="game-item-mid game-item">
                <b-col lg="6" md="12" cols="12" class="left mb-4">
                    <p class="p-case-header">
                        <img class="img-fluid img-case" :src="$store.state.baseUrl + '/images/game/icon/icon-908.png'">
                        <span class="name-box">
                            <a target="_blank" href="http://dn.sdo.com/web11/home" class="name-title"><span class="ml-3">龙之谷 <span class="name-small">(试玩)</span></span></a>
                            <span class="name-toast ml-3"><span>云存档</span></span>
                        </span>
                    </p>
                    <p class="p-case-content">广受好评的奇幻3D动作MMORPG游戏，数十种职业选择，无锁定高自由度即时战斗，带来酣畅淋漓的战场体验。云端推流技术超低延迟，确保每一个操作每一次闪避都精准可控。60帧画面输出，在复杂的战斗情景下也能够提供与客户端同等的流畅手感。</p>
                    <p class="p-case-btn">
                        <b-button @click="startGame(908)" variant="outline-secondary"><img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/pc.png">网页版</b-button>
                        <!--<b-button class="ml-4" variant="outline-secondary" @click="downloadAndroidClient()"> <img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/tv-icon.png">Android TV版</b-button>-->
                    </p>
                </b-col>
                <b-col lg="6" md="12" cols="12" class="left mb-4">
                    <a target="_blank" @click="gameDetail(908)" class="game-item-img"><img class="img-fluid" src="@/assets/images/officialWebsite/dragonNest-bg.png" title="点击可了解游戏详情"></a>
                </b-col>
            </b-row>
        </b-container>
    </div>
    <div class="game-case select-bd">
        <b-container>
            <b-row align-v="center" class="game-item">
                <b-col lg="6" md="12" cols="12" class="left mb-4">
                    <a target="_blank" @click="gameDetail(901)" class="game-item-img"><img class="img-fluid" src="@/assets/images/officialWebsite/ff14-bg.png" title="点击可了解游戏详情"></a>
                </b-col>
                <b-col lg="6" md="12" cols="12" class="left mb-4">
                    <p class="p-case-header">
                        <img class="img-fluid img-case" :src="$store.state.baseUrl + '/images/game/icon/icon-901.png'">
                        <span class="name-box">
                            <a target="_blank" href="https://adsrff.web.sdo.com/web1/" class="name-title"><span class="ml-3">最终幻想14 <span class="name-small">(试玩)</span></span></a>
                            <span class="name-toast ml-3"><span>云存档</span></span>
                        </span>
                    </p>
                    <p class="p-case-content">艾欧泽亚大陆为光之战士们开启了新的通道， 超人气MMORPG降临云端。支持多种操作模式，网页键鼠无障碍副本战斗，手机触控随时随地赛鸟打牌，TV手柄尽享大屏快感。云端存档功能免去用户数据迁移麻烦，针对移动平台操控优化，提供全平台人性化游戏体验。</p>
                    <p class="p-case-btn">
                        <b-button @click="startGame(901)" variant="outline-secondary"><img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/pc.png">网页版</b-button>
<!--                        <b-button class="ml-4" variant="outline-secondary" @click="downloadAndroidClient('cn.dygame.android.ff14')"> <img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/tv-icon.png">Android TV版</b-button>-->
                    </p>
                </b-col>
            </b-row>
        </b-container>
    </div>
    <div class="game-case">
        <b-container>
            <b-row align-v="center" class="game-item-mid game-item">
                <b-col lg="6" md="12" cols="12" class="left mb-4">
                    <p class="p-case-header">
                        <img class="img-fluid img-case" :src="$store.state.baseUrl + '/images/game/icon/icon-907.png'">
                        <span class="name-box">
                            <a target="_blank" href="https://aion.web.sdo.com/"><span class="ml-3">永恒之塔 <span class="name-small">(试玩)</span></span></a>
                        </span>
                    </p>
                    <p class="p-case-content">《永恒之塔》是由NCsoft开发，盛趣代理的3D多人在线角色扮演游戏。以战争为主题，向玩家展示神族、魔族、天族永无休止的三方大战，丰富的职业选择，宏大的世界观构建以及多达千人的PVP战斗都令人沉迷其中。在云技术加持下，玩家能够通过各个云端平台迅速上线，参与到保卫领地的战斗中。</p>
                    <p class="p-case-btn">
                        <b-button @click="startGame(907)" variant="outline-secondary"><img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/pc.png">网页版</b-button>
                        <!--<b-button class="ml-4" variant="outline-secondary" @click="downloadAndroidClient()"> <img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/phone.png">安卓版</b-button>-->
                    </p>
                </b-col>
                <b-col lg="6" md="12" cols="12" class="left mb-4">
                    <a target="_blank" @click="gameDetail(907)" class="game-item-img"><img class="img-fluid" src="@/assets/images/officialWebsite/aion-bg.png" title="点击可了解游戏详情"></a>
                </b-col>
            </b-row>
        </b-container>
    </div>



<!--    <div class="game-case">-->
<!--        <b-container>-->
<!--            <b-row align-v="center" class="game-item-mid game-item">-->
<!--                <b-col lg="6" md="12" cols="12" class="left mb-4">-->
<!--                    <p class="p-case-header">-->
<!--                        <img class="img-fluid img-case" src="https://dygame.cn/images/game/icon/icon_600.png">-->
<!--                        <span class="name-box">-->
<!--                            <a target="_blank" href="https://gmys.web.sdo.com/" class="name-title"><span class="ml-3">光明勇士 <span class="name-small">(试玩)</span></span></a>-->
<!--                            <span class="name-toast ml-3"><span>云手游</span></span>-->
<!--                        </span>-->
<!--                    </p>-->
<!--                    <p class="p-case-content">次世代萌系3D手游，不需要高性能硬件即可获得最佳品质的画面效果。得益于云服务特性，一键即可将游戏收至后台，在云端服务器上保持运行，手机息屏挂机不耗电。云截屏回放功能，纪录游玩过程中的有趣瞬间，不消耗手机内存也能将精彩操作保留下来。</p>-->
<!--                    <p class="p-case-btn">-->
<!--                        <b-button @click="startGame(1001)" variant="outline-secondary"><img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/pc.png">网页版</b-button>-->
<!--                        <b-button class="ml-4" variant="outline-secondary" @click="downloadAndroidClient('cn.dygame.android.gmys')"> <img class="img-fluid mr-2 btn-case" src="@/assets/images/officialWebsite/phone.png">安卓版</b-button>-->
<!--                    </p>-->
<!--                </b-col>-->
<!--                <b-col lg="6" md="12" cols="12" class="left mb-4">-->
<!--                    <a target="_blank" @click="gameDetail(1001)" class="game-item-img"><img class="img-fluid" src="@/assets/images/officialWebsite/brave.png" title="点击可了解游戏详情"></a>-->
<!--                </b-col>-->
<!--            </b-row>-->
<!--        </b-container>-->
<!--    </div>-->

    <transition name="el-fade-in">
        <div class="backtop"  >
            <a href="/#/question/" target="_blank" title="常见问题">
                <i></i>
                <!-- <div>
                    <ul class="questionList">
                        <li>
                            <div>
                                <i class="i-question-menu"></i>
                                <span>意见反馈</span>
                                <i class="i-question-right"></i>
                            </div>
                        </li>
                        <li>
                            <a href="/#/question/">
                                <i class="i-question-warn"></i>
                                <span>常见问题</span>
                                <i class="i-question-right"></i>
                            </a>
                        </li>
                    </ul>
                </div> -->
            </a>
            <a href="#backtopLink" title="回到顶部">
                <i v-show="backTopStatus"></i>
            </a>
        </div>
    </transition>

    <!-- 合作伙伴 -->
    <div class="partner">
        <p class="partner-title">合作伙伴</p>
        <b-container>
            <b-row>
                <b-col lg="6" md="6" cols="12" class="mb-5">
                    <div class="part-game-case">
                      <a target="_blank" href="https://www.sdo.com/"><img class="part-img img-fluid" src="@/assets/images/officialWebsite/Shengqu.png" alt="盛趣游戏"></a>
                    </div>
                </b-col>
                <b-col lg="6" md="6" cols="12" class="mb-5">
                    <div class="part-game-case">
                      <a target="_blank" href="https://cloud.tencent.com/"><img class="part-img img-fluid" src="@/assets/images/officialWebsite/tencent.png" alt="腾讯云"></a>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
    <Footer></Footer>
    <DownLoadModal :resource="transData" :downloadShow="downloadShow" @downloadStop="changeDownload"></DownLoadModal>
</div>
</template>

<script>
import DownLoadModal from "@/components/DownLoadModal"
import launchGameUtil from "@/components/cloudGameLauncher/launchGameUtil";
let base64 = require("js-base64").Base64;
export default {
    name: 'Index',
    components: {
        DownLoadModal
    },
    data() {
        return {
            downloadShow: false,
            transData: {
                resource: "",
                loadHref: ""
            },
            backTopStatus: false,
            imgGroup: [
                {
                    src:require('../../../assets/images/officialWebsite/bg-game-max.png'),
                    minSrc:require('../../../assets/images/officialWebsite/bg-game-min.png'),
                    href: this.$store.state.baseUrl + "/#/sdo/game/900"
                },
                // {
                //     src:require('../../../assets/images/officialWebsite/bg-game1-max.png'),
                //     minSrc:require('../../../assets/images/officialWebsite/bg-game1-min.png'),
                //     href: this.$store.state.baseUrl + "/#/sdo/game/909"
                // }
            ],
            imgHeight: sessionStorage.getItem('imgHeight'),
            imgMinHeight: sessionStorage.getItem('imgMinHeight')
        }
    },
    mounted() {
        window.addEventListener("load", this.windowResize);
        window.addEventListener("scroll", this.windowScroll,true);
        window.addEventListener("resize", this.windowResize);
    },
    computed: {
        isSdoAccount: function () {
            return this.$store.state.login.user ? ((this.$store.state.login.user.platform ?? '') == 'daoyu' ? true : false) : false;
        }
    },
    methods: {
        changeDownload(status) {
            this.downloadShow = status
        },
        gameDetail(gameId) {
            this.$router.push({path: '/game/' + gameId})
        },
        startGame(gameId, useSdoAccount = false) {
            launchGameUtil.startGameReview(gameId, this, useSdoAccount);
        },
        downloadAndroidClient (packageName) {
            this.$API.getApkResourceInfo({
                    'package_name': packageName
                }, {
                    "app-id": "cn.dygame.web"
                })
                .then((response) => {
                    let res = response.data;
                    if (res.status) {
                        let isMobile = res.data.isMobile;
                        this.transData.loadHref = res.data.resource || '';
                        let qrCode = res.data.qrCode || '';
                        if (!isMobile && qrCode) {
                            this.downloadShow = true;
                            this.transData.resource = base64.decode(qrCode);
                        } else {
                            this.downloadShow = false;
                            window.location.href = this.transData.loadHref;
                        }
                    }
                });
        },
        windowScroll(){
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            this.backTopStatus = scrollTop > 1200 ? true : false;
        },
        windowResize() {
            this.imgHeight = document.getElementById('imgBig').offsetHeight + 'px'
            this.imgMinHeight = document.getElementById('imgMin').offsetHeight + 'px'
            sessionStorage.setItem('imgHeight',this.imgHeight)
            sessionStorage.setItem('imgMinHeight',this.imgMinHeight)
        }
    },
    beforeDestroy(){
        //滚动事件销毁
        window.removeEventListener("scroll",this.windowScroll);
        window.removeEventListener("load", this.windowResize);
        window.removeEventListener("resize",this.windowResize);
    }
}
</script>

<style lang="scss">
@import "@/styles/officialWebsite/index";
</style>
